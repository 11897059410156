
<template>
    <backoffice-layout :title="$tc('customer', 2)">
        <v-card>
            <v-table :rows="customers" :columns="columns" :search-options="{ enabled: true, placeholder: $t('action.search_customers') }" @on-cell-click="props => $redirect('admin-customer-detail', {id: props.row.id})" />
        </v-card>
    </backoffice-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
		return {
            columns: [{
                label: 'fullname',
                field: 'fullname',
                width: '180px'
            }, {
                label: 'mobile',
                field: 'mobile',
                type: 'tel',
                width: '150px'
            }, {
                label: 'email',
                field: 'email',
                type: 'email',
                width: '200px'
            }],
        }
    },

    computed: {
        ...mapGetters('admin_customers', [
            'customers',
        ]),
    },

    mounted () {
        this.getCustomers();
    },

    methods: {
		...mapActions('admin_customers', [
            'getCustomers',
		])
    }
}
</script>
